<template>
  <div style="width: 100%">
    <div class="position-relative">
      <div class="input-group">
        <div class="input-group-prepend">
          <span
            class="input-group-text"
            :class="{ 'cursor-pointer': !disabled }"
            @click="toggleDatePicker"
            ><i class="fa-light fa-calendar"></i
          ></span>
        </div>
        <div class="input-icon input-icon-right">
          <input
            type="text"
            class="form-control"
            :readonly="readonly"
            :disabled="disabled"
            :value="displayValue"
            :placeholder="$t(placeholder)"
            @click="toggleDatePicker"
          />
          <span v-if="displayValue" class="cursor-pointer" @click="clearInput"
            ><i class="fal fa-xmark"></i
          ></span>
        </div>
        <div class="input-group-append">
          <span
            class="input-group-text"
            :class="{ 'cursor-pointer': !disabled }"
            @click="toggleTimePicker"
            ><i class="fa-light fa-clock"></i
          ></span>
        </div>
      </div>
      <v-date-picker
        v-if="showDatePicker"
        v-model="date"
        class="position-absolute"
        @input="onDateInput"
      ></v-date-picker>
      <v-time-picker
        v-if="showTimePicker"
        v-model="time"
        class="position-absolute"
        @input="onTimeInput"
      ></v-time-picker>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/components/Tools/modifiers";

export default {
  components: {},
  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    returnFormat: {
      type: String,
      default: "YYYY-MM-DD HH:mm:ss"
    },
    displayFormat: {
      type: String,
      default: "DD.MM.YYYY HH:mm:ss"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: "tool.chooseTime"
    }
  },
  data() {
    return {
      showDatePicker: false,
      showTimePicker: false,
      date: "",
      time: ""
    };
  },
  computed: {
    displayValue: function() {
      if (this.date && !this.time) {
        return formatDate(this.date, this.displayFormat);
      } else if (!this.date && !this.time) {
        return "";
      }
      return formatDate(this.date + " " + this.time, this.displayFormat);
    }
  },
  mounted() {
    this.setValues();
  },
  methods: {
    hide() {
      this.$emit("input", false);
    },
    toggleDatePicker() {
      if (this.disabled) {
        this.showDatePicker = false;
        return;
      }
      this.showTimePicker = false;
      this.showDatePicker = !this.showDatePicker;
    },
    onDateInput() {
      this.showDatePicker = false;
      this.showTimePicker = true;
    },
    toggleTimePicker() {
      if (!this.date || this.disabled) {
        this.showTimePicker = false;
        return;
      }
      this.showDatePicker = false;
      this.showTimePicker = !this.showTimePicker;
    },
    onTimeInput() {
      this.showDatePicker = false;
      this.showTimePicker = false;
      this.updateModel();
    },
    updateModel() {
      if (!this.date || !this.time) {
        return;
      }
      let returnDate = formatDate(
        `${this.date} ${this.time}`,
        this.returnFormat
      );
      this.$emit("input", returnDate);
      this.$emit("change");
    },
    clearInput() {
      this.date = "";
      this.time = "";
      this.showDatePicker = false;
      this.showTimePicker = false;
      this.$emit("input", "");
      this.$emit("change");
    },
    setValues() {
      if (!this.value) {
        return;
      }
      this.date = formatDate(this.value, "YYYY-MM-DD");
      this.time = formatDate(this.value, "HH:mm:ss");
    }
  }
};
</script>

<style lang="scss">
.input-group {
  .form-control {
    border-radius: 0;
  }
}
</style>
