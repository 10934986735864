<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("reporting.lastIterations") }}
        </h3>
      </div>
    </div>

    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <div class="card-body column">
      <TableWrapper
        :meta="meta"
        :disable-search="true"
        @reload-data="loadQueuedJobs"
      >
        <template #filter>
          <!--          <v-select
            v-model="selectedStatus"
            multiple
            class="form-control mt-0 mr-1"
            :items="status"
            :placeholder="$t('reporting.status')"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            @change="loadQueuedJobs()"
          >
            <template #selection="{ item, index }">
              <span v-if="index === 0" class="font-size-lg ml-3">
                {{ getProcessStatus(item) }}
              </span>

              <span v-if="index === 1" class="grey&#45;&#45;text caption ml-1">
                {{
                  $t("reporting.selectionOther", {
                    count: selectedStatus.length - 1
                  })
                }}
              </span>
            </template>
            <template #item="{ item }">{{ getProcessStatus(item) }} </template>
          </v-select>-->
          <v-select
            v-model="selectedQueue"
            multiple
            item-value="name"
            class="form-control mt-0 mr-1"
            :items="queues"
            :placeholder="$t('reporting.queue')"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            @change="loadQueuedJobs()"
          >
            <template #selection="{ item, index }">
              <span v-if="index === 0" class="font-size-lg ml-3">
                {{ getQueueType(item) }}
              </span>
              <span v-if="index === 1" class="grey--text caption ml-1">
                {{
                  $t("reporting.selectionOther", {
                    count: selectedQueue.length - 1
                  })
                }}
              </span>
            </template>
            <template #item="{ item }">{{ getQueueType(item) }} </template>
          </v-select>
          <v-select
            v-model="selectedTime"
            class="form-control mt-0 mr-1"
            :items="timeframes"
            :placeholder="$t('reporting.time')"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            @input="setDateTimes"
            @change="loadQueuedJobs()"
          >
          </v-select>
          <DateTimePicker
            v-model="selectedTimeFrom"
            class="mt-0 mr-1"
            return-format="x"
            :disabled="selectedTime !== 'chooseOption'"
            :readonly="selectedTime === 'chooseOption'"
            :placeholder="'reporting.chooseTimeFrom'"
            style="width: 100%;z-index: 5;"
            @change="loadQueuedJobs()"
          ></DateTimePicker>
          <DateTimePicker
            v-model="selectedTimeTo"
            class="mt-0"
            return-format="x"
            :disabled="selectedTime !== 'chooseOption'"
            :readonly="selectedTime === 'chooseOption'"
            :placeholder="'reporting.chooseTimeTo'"
            style="width: 100%;z-index: 5;"
            @change="loadQueuedJobs()"
          ></DateTimePicker>
        </template>
        <template #default>
          <b-table
            responsive
            :items="queuedJobs"
            :fields="fields"
            class="dataTable table table-head-custom"
            :show-empty="true"
            :empty-text="$t('table.noRecords')"
          >
            <template #cell(status)="data">
              <div class="row justify-center">
                <i
                  v-b-tooltip.bottom.noninteractive="
                    getStatusTooltipText(data.item.processIteration.status)
                  "
                  :class="getStatusIcon(data.item.processIteration.status)"
                />
              </div>
            </template>

            <template #cell(queue)="data">
              <span>{{ getQueueType(data.item.queue) }}</span>
            </template>

            <template #cell(reserved_at)="data">
              <span v-if="data.item.reserved_at">
                {{ formatDateAssigned(data.item) }}
              </span>
              <span v-else></span>
            </template>

            <template #cell(available_at)="data">
              <span v-if="data.item.available_at">
                {{ formatDateAssigned(data.item.available_at) }}
              </span>
              <span v-else></span>
            </template>

            <template #cell(created_at)="data">
              <span v-if="data.item.created_at">
                {{ formatDateAssigned(data.item.created_at) }}
              </span>
              <span v-else></span>
            </template>

            <template #cell(actions)="data">
              <a
                v-b-tooltip.top.noninteractive="
                  $t('workflowDesigner.openReporting')
                "
                class="btn btn-icon btn-light btn-sm mr-1"
                @click.left="routeToLogs(data.item)"
                @click.middle="routeToLogs(data.item)"
              >
                <i class="fal fa-search" />
              </a>
              <a
                v-b-tooltip.top.noninteractive="$t('reporting.openPayload')"
                class="btn btn-icon btn-light btn-sm mr-1"
                @click.left="showPayloadModal(data.item)"
                @click.middle="showPayloadModal(data.item)"
              >
                <i class="fal fa-info" />
              </a>
            </template>
          </b-table>
        </template>
      </TableWrapper>
      <b-modal
        id="data-explorer-modal"
        v-model="modal.show"
        size="xl"
        :title="modal.title"
      >
        <vue-json-pretty highlight-mouseover-node :data="modal.data" />

        <template #modal-footer>
          <button class="btn btn-primary" @click="closeModal">
            {{ $t("general.close") }}
          </button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  calculateDuration,
  formatDateAssigned,
  getProcessStatus,
  getQueueType,
  getStatusIcon,
  getStatusTooltipText,
  millisecondsToTime
} from "@/components/Projects/Workflows/Reporting/utils";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";
import QueuedJobs from "@/components/Tenants/QueuedJobs/queuedJobs";
import Queues from "@/components/Projects/Settings/Queues/queues";
import DateTimePicker from "@/components/Tools/DateTimePicker";

export default {
  components: {
    TableWrapper,
    DateTimePicker
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["workflow"],
  data() {
    return {
      queuedJobs: [],
      searchTitle: "",
      statusSearchTerm: "",
      searchTerm: "",
      statusTitle: "",
      status: [
        "process.aborted",
        "process.error",
        "process.info",
        "process.pending",
        "process.started",
        "process.stopped",
        "process.waiting",
        "process.warning",
        "process.working"
      ],
      selectedStatus: [],
      queues: [],
      selectedQueue: [],
      timeframes: [
        {
          text: this.$t("queues.beforeFiveMinutes"),
          value: 5 * 60 * 1000
        },
        {
          text: this.$t("queues.beforeTenMinutes"),
          value: 10 * 60 * 1000
        },
        {
          text: this.$t("queues.beforeFifteenMinutes"),
          value: 15 * 60 * 1000
        },
        {
          text: this.$t("queues.beforeThirtyMinutes"),
          value: 30 * 60 * 1000
        },
        {
          text: this.$t("queues.customDateTime"),
          value: "chooseOption"
        }
      ],
      selectedTime: "",
      selectedTimeFrom: "",
      selectedTimeTo: "",
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          thStyle: { width: "70px" },
          sortable: true
        },
        {
          key: "process.name",
          label: this.$t("workflowDesigner.workflow"),
          sortable: true
        },
        {
          key: "processIteration.content_identifier",
          label: this.$t("workflowDesigner.contentIdentifier"),
          sortable: true
        },
        {
          key: "status",
          label: this.$t("reporting.status"),
          sortable: true,
          thClass: "text-center",
          thStyle: { width: "140px" }
        },
        {
          key: "queue",
          label: this.$t("reporting.queue"),
          sortable: true,
          thStyle: { width: "100px" }
        },
        {
          key: "reserved_at",
          label: this.$t("table.startedAt"),
          sortable: true
        },
        {
          key: "available_at",
          label: this.$t("table.availableAt"),
          sortable: true
        },
        {
          key: "created_at",
          label: this.$t("table.createdAt"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "100px" }
        }
      ],
      modal: {
        show: false,
        data: undefined,
        dataString: "",
        title: "",
        editMode: false
      },
      meta: {},
      isBusy: false
    };
  },
  created() {
    this.$root.$refs.WorkflowsReportingJobs = this;
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    this.loadQueuedJobs();
    this.loadAvailableQueues();
  },
  methods: {
    millisecondsToTime,
    getStatusTooltipText,
    getProcessStatus,
    getQueueType,
    getStatusIcon,
    formatDateAssigned,
    calculateDuration,
    loadQueuedJobs() {
      let params = this.requestParams();

      if (this.isBusy) {
        return false;
      }

      this.isBusy = true;
      Object.assign(params, this.getAdditionalRequestParams());

      QueuedJobs.getAll(params)
        .then(response => {
          this.queuedJobs = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(e => {
          console.log(e);
          this.isBusy = false;
        });
    },
    getAdditionalRequestParams() {
      return {
        filters: {
          status: this.selectedStatus,
          queue: this.selectedQueue,
          from: this.selectedTimeFrom / 1000,
          to: this.selectedTimeTo / 1000
        }
      };
    },
    routeToLogs(item) {
      let processId = item.processIterationId;
      this.$router.push({
        name: "projectWorkflowsJobDetails",
        params: { id: processId, jobId: item.id }
      });
    },
    loadAvailableQueues() {
      Queues.getAll()
        .then(response => {
          this.queues = response.data.data;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    showPayloadModal(data) {
      this.modal = {
        title: this.$t("reporting.payloadTitle"),
        data: data,
        show: true
      };
    },
    closeModal() {
      this.modal = {
        show: false,
        data: undefined,
        title: ""
      };
    },
    setDateTimes(ms) {
      if (this.selectedTime === "chooseOption") {
        this.selectedTimeTo = "";
        this.selectedTimeFrom = "";
        return;
      }
      let date = Date.now();
      this.selectedTimeFrom = date - ms;
      this.selectedTimeTo = date;
    }
  }
};
</script>

<style lang="scss">
.modal-body {
  max-height: 80vh;
  overflow: auto;
}

.input-group {
  flex-wrap: nowrap;
}
</style>
